import {isValidPhoneNumber} from "libphonenumber-js";

export const FORMULATE_PLUGIN_OPTIONS = {
  library: {
    attributes: {
      component: 'FormulateDynamicAttributes',
      slotProps: {
        component: ['definition'],
      }
    },
    relationAssociation: {
      component: 'FormulateRelationAssociation',
      slotProps: {
        component: ['collection', 'remote', 'owner'],
      }
    },
    joinComposition: {
      component: 'FormulateJoinComposition',
      slotProps: {
        component: ['collection', 'fields']
      }
    },
    joinAssociation: {
      component: 'FormulateJoinAssociation',
      slotProps: {
        component: ['collection', 'constrain']
      }
    },
    radioButtons: {
      component: 'FormulateRadioButtons',
    }
  },
  rules: {
    phone: ({value}) => isValidPhoneNumber(value, 'DE')
  },
  locales: {
    en: {
      phone: ({value}) => `Phone number ${value} is not valid`
    }
  },
}